/* HEADER CSS */

.header {
    width: 100%;
    background: linear-gradient(45deg,white 10% ,var(--blue) 50%, var(--secondary) 90%);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.63)
}

.header_row {
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    min-height: 64px;
}

.nav_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.nav_logo img {
    height: 40px;
    width:auto;
    object-fit: contain;
}



/*nav links  */

.menu_list {
    flex: 1;
    margin: 0 auto;
    padding: 0 16px;
    user-select: none;
}

.menu_list>ul {
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 1;
    list-style: none;
}

.menu_list>ul>li {
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0 8px;
}

.menu_list>ul>li i {
    display: none;
    margin-left: 6px;
}

.menu_list>ul>li a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    transition: 0.4s all;
    padding: 6px 4px;
    font-weight: 600px;
    width: 100%;
    display: block;
}

.menu_list a:hover {
    color:var(--primary) ;
}

.menu_list ul li:hover ul {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);

}


.menu_list>img {
    display: none;
    width: 100%;
    max-width: 110px;
    margin: 12px auto;
    text-align: center;
}

.header_menu_btn {
    display: none;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 6px 10px;
    cursor: pointer;
    transition: 0.4s all;
    position: relative;
    border: none;
    color: white;
    font-size: 1.3rem;
    margin-left: 6px;
    cursor: pointer;
    width: 39px;
    text-align: center;
}


/* mobile responsive */

@media screen and (max-width: 890px) {

    .header{
        background: linear-gradient(45deg,white 50%,var(--blue) 80% ,var(--secondary));
    }
    .header_row {
        padding: 5px 16px 5px 23px;
        min-height: 54px;
    }
    .nav_logo img {
       height : 35px;
    }

    .menu_list>img {
        display: block;
    }
    .menu_list {
        position: fixed;
        top: 0;
        left: 0;
        width: 275px;
        background: white;
        min-height: 100vh;
        flex-direction: column;
        transition: transform .3s linear;
        transform: translateX(-100%);
        z-index: 2;
        text-align: center;
    }
    .menu_list.menuOpened {
        transform: translateX(0)
    }
    .menu_list>ul {
        flex-direction: column;
        padding-top: 20px;
    }
    .menu_list>ul>li {
        display: block;
        margin: 10px auto;
        width: 100%;
    }
    .menu_list>ul>li i {
        display: inline-block;
    }
    .menu_list>ul>li a {
        font-size: 1.1rem;
        font-weight:bold;
        text-align: center;
        color:var(--primary)
    }
    .menu_list a:hover {
        color: var(--blue) ;
    }

    .nested_menu {
        position: unset;
        top: unset;
        left: unset;
        width: 100%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.164);
        opacity: 1;
        pointer-events: all;
        transform: unset;
        height: 0;
        overflow: hidden;
        padding: 0
    }
    .menu_list ul li:hover ul {
        height: auto;
        padding: 6px;
    }
    .nested_menu li {
        display: block;
        width: 100%;
        margin: 2px auto;
    }
    .nested_menu li a {
        display: block;
        padding: 6px 13px!important;
    }
    .header_menu_btn {
        display: flex;
    }
    .header_backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
        background: rgba(0, 0, 0, 0.137);
    }
}

@media screen and (max-width: 318px) {
    .menu_list {
        width: 240px;
    }
}