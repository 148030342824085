.img_content{
    background-position: 0% 70%;
}

.news h2{
    color:white;
}

.news_card_row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0;
}

.news_col {
    width: 25%;
    padding: 10px 25px;
    display:flex;
    flex-direction: col;
}

.news_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    transition: 0.5s all;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: var(--primary);
}

.news_card img {
    border-radius: 5px;
    object-fit: cover;
    object-position: center;;
    height: 20vh;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.news_card:hover {
    transform: translateY(-10px);
}

.news_card:hover .news_card_content {
    box-shadow: 0px 15px 30px 0px rgb(18 55 97 / 15%);
}

.news_card_content {
    transition: 0.5s all;
    display: flex;
    padding: 25px 20px;
    flex-direction: column;
    border-bottom-left-radius: 5px;

    ;
    border-bottom-right-radius: 5px;
}

.news_card_content h2 {
    font-size: 1.1rem;
    color: var(--green);
}

.news_card_content p {
    margin-top:10px;
    font-size: 0.9rem;
    color: #f5f5f5;
}


/* NEWS BLOG */

.news_detail_page {
    width: 100%;
    padding: 118px 0 80px 0;
}

.news_detail_sec {
    width: 75%;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    border-right: 1px solid #80808029;
}

.news_detail_sec h5 {
    color: var(--secondary);
}

.news_detail_sec h2 {
    margin: 20px 0 40px 0;
    font-size: 2.2rem;
    color: var(--primary);
    line-height: 1.3;
    font-family: "Frutiger_bold";
}

.news_detail_sec p {
    margin: 20px 0;
    color: var(--primary);
}

.news_detail_related_sec {
    flex: 1;
    width: 100%;
    padding-left: 9px;
}

.news_detail_related_sec .news_card_row {
    display: flex;
    flex-direction: column;
}

.news_detail_related_sec .news_col {
    width: 100%;
    margin: 20px 0;
    padding: 9px 0;
}

.news_detail_img {
    overflow: hidden;
    min-height: 350px;
    background-color: #318e3f41;
    transition: 0.6s all;
    border-radius: 6px;
    overflow: hidden;
}

.news_detail_img img {
    max-height: 550px;
    width:100%;
    object-fit: cover;
}


/* responsive */

@media screen and (max-width: 860px) {
    .news_col {
        width: 33.33%;
    }
}

@media screen and (max-width: 767px) {
    .news_col {
        width: 50%;
        padding: 9px 6px;
    }
}

@media screen and (max-width: 767px) {
    .news_card_row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 30px 0 26px 0;
    }
    .news_col {
        width: 90%;
        padding: 0px;
    }
    .news_card_content {
        padding: 16px 12px;
    }
    .news_card_content h2 {
        font-size: 1rem;
    }
    .news_card_content p {
        font-size: .89rem;
    }
    /*  */
    .news_detail_page {
        padding: 90px 0 20px 0;
    }
    .news_detail_row {
        flex-wrap: wrap;
    }
    .news_detail_sec {
        width: 100%;
        padding: 0!important;
        border-bottom: 1px solid #80808029;
        border-right: none;
    }
    .news_detail_sec h2 {
        margin: 2px 0 12px 0;
        font-size: 1.3rem;
    }
    .news_detail_sec p {
        margin: 16px 0;
    }
    .news_detail_related_sec {
        flex: none;
        width: 100%;
        padding-left: 0;
        padding-top: 13px;
    }

    
    .news_detail_related_sec .news_card_row {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
    }
    .news_detail_related_sec .news_col {
        width: 50%;
        margin: 4px 0;
        padding: 9px 3px;
    }
    .news_detail_img {
        min-height: 280px;
    }

}

@media screen and (max-width: 335px) {
    .news_col {
        width: 100%;
    }
    .news_card_content {
        padding: 12px 9px;
    }
    .news_card_content h2 {
        font-size: .9rem;
    }
    .news_card_content p {
        font-size: .79rem;
    }
    /*  */
    .news_detail_related_sec .news_col {
        width: 100%;
    }
    .news_detail_img {
        min-height: 220px;
    }
    .news_detail_sec h2 {
        margin: 2px 0 9px 0;
        font-size: 1.1rem;
    }
}