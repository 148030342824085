.c_hero {
    width: 100%;
    min-height: 80vh;
    background-image: url("https://img.freepik.com/free-photo/woman-hands-holding-credit-card-using-computer-laptop_1391-495.jpg");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position:relative;
}

.c_hero .overlaytop {
    background: linear-gradient(90deg, white 80%, #00000000 85%);
    padding:120px 40px;
    width:50vw;
    height: 80vh;
    display:flex;
    justify-content: flex-start;
}

.c_content h2 {
    font-size: 2.5rem;
    font-weight: 800;
    color: var(--green);
}

.c_content p {
    max-width: 550px;
    color: var(--blue);
    margin: 20px 0px;
    width:80%;
    font-size:0.9rem;
    font-weight:600;
}

.c_content button {
    padding:8px 16px;
    font-size:0.8rem;
    margin-top:20px;
    background: linear-gradient(180deg,var(--green), var(--secondary)  );
    border:none;
    border-radius:5px;
    color:white;
    font-weight: 500;
    transition: ease-in-out 0.2s;
    cursor: pointer;
}

.c_content button:hover{
    background: linear-gradient(45deg,black ,var(--secondary) );
    color:var(--green);
    border:var(--blue) 1px solid;
    border-style: inset;
}


#form{
    scroll-margin-top: 150px;
max-width:1000px;}


.contact{
    background: linear-gradient(45deg,var(--primary) ,var(--primary));
    padding-bottom:40px;
}

.contact_form {
    margin: 100px auto 55px auto;
    width:85%
}

.cf_left {
    width: 65%;
}

.cf_left form {
    background-color: #fff;
    border-radius: 10px 0px 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space;
    flex-wrap: wrap;
    padding: 40px;
}

.cf_left form label {
    color: var(--black);
    margin-bottom: 10px;
    opacity: 0.6;
    font-weight:800;
}

.cf_left form .cs_row {
    width: 100%;
    justify-content: space-between;
}

.cf_left form .form_group {
    width: 49%;
}

.cf_left form input,
.cf_left textarea {
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.459);
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 8px;
}

.cf_left textarea {
    width: 100%;
    padding: 10px;
}

input:focus,
textarea:focus {
    border: 1px solid transparent;
    outline: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.cf_right {
    width: 35%;
    padding: 40px 20px;
    background: linear-gradient(45deg,var(--secondary), var(--green), var(--secondary)  );
    border-radius:0px 10px 10px 0px;
}

.cf_right>div {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}


.cf_right>.invert img {
    margin-right: 20px;
    width: 25px;
    margin-top: 5px;
    object-fit: contain;
    filter:invert(1) hue-rotate(150deg) saturate(250%)
}

.cf_right>div img{
    height:25px;
}

.icons_right a{
    margin:6px;
    margin-top:15px;
    filter: hue-rotate(150deg) saturate(250%) brightness(150%);
}

.icons_right a:hover{
    filter:invert(1) saturate(0) brightness(200%)
}

.cf_right>div div {
    color: black;
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    flex-direction: column;
}

.submit_button{
        margin:auto;
         margin-top:12px;
         background:linear-gradient(180deg,var(--green), var(--secondary)  );
         color:white;
         font-size:1.2rem;
}

.submit_button:hover{
     background:linear-gradient(45deg,var(--secondary), var(--primary)  );
     color:var(--green);
}






@media screen and (max-width: 900px) {
    .c_hero{
        background-size: cover;
    }
    
    .c_hero .overlaytop{
        width:90%;
        padding:120px 10px 0 10px;
    }

    .c_content{
        width:90%;
        margin:auto;
        margin-top:2vh;
    }
    .contact_form {
        margin: 40px auto 25px auto;
        flex-wrap: wrap;
    }
    .cf_left {
        width: 100%;
        margin-bottom: 20px;
    }
    .cf_right {
        width: 100%;
        padding: 20px 10px;
        border-radius: 12px;
    }
    .cf_left form {
        padding: 20px;
        border-radius: 10px;
    }
    .cf_left form .cs_row {
        flex-wrap: wrap;
    }
    .cf_left form .form_group {
        width: 100%;
    }
    .cf_left form input,
    .cf_left textarea {
        font-size: 15px;
    }

}

.social-icons2 {
    flex-direction: row;
    margin:30px 0px;
    display: flex;
    align-items: center
  }


  ::marker{
    opacity:0;
    color:transparent;
  }

  .iconz a {
    border-radius: 10%;
    height:30px;
    background-color: var(--primary);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.2s ease-out;
  }
  
  .iconz a:hover {
    background-color: var(--blue);
    background-size: 0%;
    transition: all 0.2s ease-out;
  }
  .icon__name {
    width: 50%;
    color: #fff;
    font-size: 9px;
    text-align: center;
    transition: all 0.3s ease-out;
    opacity: 0;
    filter: blur(25px);
    transform: translateZ(0);
    will-change: transform;
  }
  .iconz:hover .icon__name {
    transition: all 0.3s ease-out;
    opacity: 1;
    filter: blur(0);
  }
  
  .icon--linkedin a {
    padding: 10px 5px;
    background-image: url('https://benhodgson.net/img/social/linkedin.svg');
  }
 
  