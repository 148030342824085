.footer {
    width: 100%;
    background-color: white;
    margin-top: auto;
    box-shadow: 0 -4px 15px #0000001a;
    border-top: 1px solid rgba(128, 128, 128, 0.205);
}

.footer_top {
    border-bottom: 1px solid rgba(128, 128, 128, 0.397);
}

.footer_row {
    padding: 30px 40px;
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.footer_left {
    width: 35%;
}

.footer_left img {
    width: 150px;
}

.footer-text{
   display: flex;
   gap:40px;
   font-size:0.7rem;
   
}

.footer_nav_col ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction:column;
}

.footer_nav_col ul li a, .email {
    padding: 2px 0;
    text-decoration: none;
    display: block;
    margin: 1px 0;
    color: var(--secondary);
    font-weight: 600;
}

.footer_nav_col ul li a:hover, .email:hover {
    color: var(--blue);
}

.footer-text h4 {
    position: relative;
    margin-bottom: 12px;
    text-transform: capitalize;
}

.footer-text h4::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 45px;
    height: 3px;
    background-color: var(--primary);
    content: "";
    border-radius: 50px;
}


@media screen and (max-width: 767px) {
    .footer_row {
        flex-direction: column;
        gap:30px;
    }
    .footer_left {
        width: 100%;
        text-align: center;
    }
    .footer_left img {
        width: 100%;
        max-width: 160px;
        margin: 0 auto;
    }

    
    .footer_nav_col ul {
        justify-content: center;
        margin: 0 auto;
    }

}