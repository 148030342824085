
.hero {
  margin-top:64px;
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
}


.hero video {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    filter: brightness(30%) blur(2px);
}






.overlay {
  margin-top:64px;
    width: 100%;
    height: 80vh;
    top: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%,0%);
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:650px;
    z-index:2;
}

.overlay img{
    z-index:1;
    max-height:400px;
    height:45vh;
    width:550px;
    padding-bottom:20px;
    margin-bottom:10px;
    position:relative; 
}

.overlay h3{
    color:white;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    max-width: 850px;
    font-size:larger;
    text-align:center;
    font-style: italic;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding:15px 0px;
}

.overlay button{
    padding:8px 16px;
    font-size:1rem;
    margin-top:20px;
    background: linear-gradient(180deg,var(--green), var(--secondary)  );
    border:none;
    border-radius:5px;
    color:white;
    font-weight: 500;
    transition: ease-in-out 0.2s;
    cursor: pointer;
}

.overlay button:hover{
    background: linear-gradient(45deg,black ,var(--secondary) );
    color:var(--green);
    border:var(--blue) 1px solid;
    border-style: inset;
}








.scale-in-center {
	-webkit-animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
	        animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

.bg-dark{
  background:var(--primary);
}


h1{
  font-family: "Arial Rounded MT Bold", Arial, sans-serif;
  color:var(--green);
  margin: 0px 100px 30px 120px;
  font-size:2.5rem;
  padding:80px 5px 10px 5px;
  border-bottom: 4px solid var(--secondary);
  width:30%;
}

h1 span{
  color:var(--blue)
}






.introduction{
  padding:20px 30px 100px 30px;
  display:flex;
  align-items:start;
  justify-content:space-between;
  gap:30px;
  margin:auto;
  width:90%;
  max-width: 1400px;
}



.group-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:20px 0px;
  width:15%;
}

.icon{
  width:60px;
  height:60px;
  fill:var(--green)
}

.introduction p{
  text-align: center;
  font-size:14px;
  color:white;
  margin-top:15px;
}









.subsidiaries .content{
  background: linear-gradient(0deg, var(--green), var(--blue));
  opacity:0.4;
  width:fit-content;
  margin:200px auto;
  border-radius: 10px;
  box-shadow: 5px 5px 15px black;
 }

 .subsidiaries p{
  font-size:2rem;
  color:white;
  padding:16px 32px;;
 }







.OurStory{
  padding:0px 0px 50px 0px;
  height: max-content;
  background-image: url("../../assets/images/ourStory.png");
  background-size: cover;
  background-position: 0% 50%;
 
}

.story-content{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:40px;
  width:70%;
  margin: 100px auto 100px auto;
  padding:30px 10px;
  background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.6);
backdrop-filter: blur(5px) brightness(0.8);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.OurStory p{
  width:90%;
  color:white;
  margin:auto;
  font-size:16px;
  line-height: 1.5em;
  flex:1.7;
  font-weight: 600;

}

.OurStory img{
  flex:1;
  width:40%;
  max-width:600px;
  height:300px;
  object-fit: cover;
  border:var(--green) 2px solid
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }

  .fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
  }

  /**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

 

@media screen and (max-width: 800px) {

  .hero {
    margin-top:54px;
  }
    .hero-spacer {
        background-image: none;
    }
    .overlay {
        width: 100%;
        margin:auto;
        align-items: center;
        padding-top:0vh;
        padding-left:0vh;
        margin-top:54px;
    }
   
    .overlay img{
        width:auto;
        max-width:90vw;
        height:auto;
        max-height:35vh;
    }


   .overlay h3 {
    width:90%;
   }

   h1{
    margin:0px 30px 20px 30px;
    width:80%;
    padding:60px 5px 20px 5px;
   }




   .subsidiaries p{
    font-size:2.5rem
   }




   .story-content{
    flex-direction: column;
    width:90%;
    padding:0;
   }

   .OurStory p{
    width:80%;
    font-size:15px;
    padding:30px 0;
   }


   .introduction{
    flex-direction: column;
    align-items:center;
    width:90%;
    padding:10px 0px 60px 0px;
   }

   .group-text{
    width:80%;
    padding:0 10px;
   }

}


  
