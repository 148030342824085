.partners_content{
    display:flex;
    flex-direction: row;
    margin:10px 3vw;
}

.partners_slider{
    width:50vw;
    margin:auto;
}
.slidep{
    height:fit-content;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right:none;
}

.slidep img{
    margin:40px auto;
    border-radius:10px;
    border: #2FAF7E 5px solid;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width:300px;
    height:auto;
}

.slidep p{
    color:white;
    font-size:1.1rem;
    font-weight:500;
    margin:auto;
padding:25px;
}


.slick-dots li button:before {
    color:White !important;
}

.slick-dots li {
    width:1vw;
    height:1vw;
}

.slick-dots li.slick-active button:before {
    color:#B3D8DE !important;
}

@media screen and (max-width: 967px) {
    .partners_content{
        flex-direction:column;
    }
    .partners_slider{
        width:85vw;
    }
    .text_partners{
        width:90vw;
        padding-bottom:40px;
        border-bottom:5px solid #C1F6EC;
    }

    .text_partners h2{
        margin-bottom:15px;
    }

    .text_partners:after{
        border-right:0px solid #C1F6EC;

    
    }
}