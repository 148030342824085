@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;900&display=swap');

.section{
  padding-bottom: 80px;
}
.team{
  display: flex;
  justify-content: center;
  width:55%;
  max-width: 900px;
  margin:auto;
  gap:20px;
 transition: ease-in-out 1s;
 margin-bottom:30px;
}

.team:first-of-type{
 padding-top:80px;
}

.container  { 
flex:1;
width:40px;
 color:white;
 height:250px;
 border-radius: 5px;
 transition: ease-in-out 1s;
 background-size: cover;
 background-position: top;
 filter: grayscale(0);
 box-shadow: 5px 5px 15px #555555aa;
}

.name{
color:white;
background:#00000033;
padding:2px 10px;
transition: ease-in-out 1s;
font-size: 15px;
text-align: center;
}

.description{
  opacity:0;
 transition: ease-in-out 2s;
 max-height: 200px;
 overflow: auto;
 margin:30px;
 padding:10px;
 font-size:medium;
}

.content{
  transition: ease-in-out 1s; 
}

.container:hover{
  flex:7;;
}

.container:hover .description{
  opacity:1;
}

.content{
 transition: ease-in-out 1s;
}

.container:hover .content{
  backdrop-filter:brightness(0.5) grayscale(1);
  height: 100%;
display: flex;
flex-direction: column;
  justify-content: space-around;
}

.container:hover .name{
  color:var(--green)
}

@media screen and (max-width: 767px) {

.team{
  gap:5px;
  width:90%;
  margin-bottom: 10px;
  flex-direction: column;
  height:500px;
  flex:1
}

.team:first-of-type{
  width:90%;
}
  .container{
    width:100%;
    padding:5px;
    height:50px;
    overflow: hidden;
    background-position: 70% 20%;
  }
  

  .description{
    padding:0;
    margin:5px;
  }
}