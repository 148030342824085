.img_content {
    background-image: var(--background);
    /* background-image: url("https://www.impactsolar.co.th/application/files/8315/2508/0720/solar_pump_bannerV1.png"); */
    width: 100%;
    min-height: 250px;
    max-height: 500px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 88%, 50% 100%, 0 88%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img_content h2 {
    font-size: 2.9rem;
    padding-top: 20px;
    z-index: 222;
}

.img_content .overlay {
    height: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.212);
}

.g_content {
    width: 90%;
    margin: 70px auto 80px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.blue_head,
.g_content h2 {
    font-size: 3rem;
}

.g_content p {
    width: 700px;
    margin: 10px auto;
    text-align: center;
}

.gaiaswap_revenue {
    position: relative;
    padding: 0 50px;
    margin-bottom: 100px;
}

.cs_list {
    padding-left: 0;
}

.cs_list li {
    margin: 8px 0;
    list-style: none;
    position: relative;
    padding-left: 20px;
    display: flex;
    padding-top: 0;
}

.cs_list li:before {
    position: absolute;
    left: -5px;
    top: 5px;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-image: linear-gradient(45deg, #000, var(--maroon) 90%);
    /* background-color: var(--maroon); */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.377);
}

.gaiaswap_revenue ul {
    padding-top: 70px;
    width: 55%;
    margin: 0;
}

.gaiaswap_revenue img {
    position: absolute;
    top: -45%;
    right: -9%;
    max-width: 390px;
}

@media screen and (max-width: 767px) {
    .img_content {
        min-height: 200px;
        max-height: 310px;
    }
    .img_content h2 {
        padding-top: 24px;
        font-size: 2.3rem;
    }
}