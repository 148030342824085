.contact-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .flip-card {
    border-radius: 0.5em;
    position: fixed;
    top: 1em;
    left: 1em;
    width: 5em;
    padding: 0.5em;
    border: 0.1em solid #fff;
    color: #fff;
    text-align: center;
    cursor: pointer;
    z-index: 9;
  }
  .envelope {
    position: relative;
    display: block;
    width: 30em;
    height: 35em;
    margin: 0 auto;
  }
  .envelope.active .content {
    padding: 15em 2em 2em;
  }
  .envelope.active .paper.front,
  .envelope.active .paper.back {
    animation-duration: 1.5s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  .envelope.active .paper.front {
    animation-name: envelope-front;
  }
  .envelope.active .paper.back {
    animation-name: envelope-back;
  }
  .envelope.active .paper.back:before {
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1.25s;
    animation-name: envelope-back-before;
  }
  .envelope.active .bottom-wrapper {
    transform: rotateX(180deg);
  }
  .envelope.active .bottom-wrapper:after {
    z-index: 0;
    opacity: 1;
  }
  .envelope .content {
    padding: 2em;
    box-sizing: border-box;
    position: relative;
    z-index: 9;
    transition: all 0.5s ease-in-out;
    transition-delay: 1s;
  }
  .envelope .top-wrapper,
  .envelope .bottom-wrapper {
    box-sizing: border-box;
    background: #03a9f5;
    color: #fff;
  }
  .envelope .top-wrapper {
    padding: 2em 2em 0;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  .envelope .bottom-wrapper {
    padding: 0 2em 2em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    transition: all 0.5s ease-in-out;
    transform-origin: top;
    transform-style: preserve-3d;
    position: relative;
    overflow: hidden;
    margin-top: -1px;
  }
  .envelope .bottom-wrapper:after {
    position: absolute;
    content: '';
    display: block;
    opacity: 0;
    background: #03a9f5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .envelope form label {
    display: block;
    padding-bottom: 0.5em;
  }
  .envelope form input,
  .envelope form textarea {
    width: 100%;
    box-shadow: 0;
    background: transparent;
    color: #fff;
  }
  .envelope form input {
    border-width: 0 0 0.1em;
    border-color: #fff;
    border-style: solid;
  }
  .envelope form textarea {
    border: 0.1em solid #fff;
    border-radius: 0.25em;
  }
  .envelope form .submit-card {
    background: #fff;
    color: #222;
    text-align: center;
    padding: 0.5em;
    box-sizing: border-box;
    background: #fff;
    width: 100%;
    border: 0;
    box-shadow: none;
    border-radius: 0.25em;
    cursor: pointer;
  }
  .envelope form .input {
    padding-bottom: 1em;
  }
  .envelope .paper {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    overflow: hidden;
  }
  .envelope .paper.back {
    top: 0;
  }
  .envelope .paper.back:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-bottom: -1px;
    border-style: solid;
    border-width: 0 15em 10em 15em;
    border-color: transparent transparent #d3d3d3 transparent;
    transform-origin: bottom;
    transform-style: preserve-3d;
    z-index: 0;
  }
  .envelope .paper.back:after {
    content: '';
    display: block;
    background-color: #d3d3d3;
    width: 30em;
    height: 20em;
  }
  .envelope .paper.front {
    top: 10em;
    box-shadow: 0.1em 0.5em 0.5em rgba(0,0,0,0.25);
    z-index: 0;
  }
  .envelope .paper.front:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10em 15em 0 15em;
    border-color: transparent #fff;
  }
  .envelope .paper.front:after {
    content: '';
    display: block;
    width: 30em;
    height: 10em;
    background: #fff;
    margin-top: -1px;
  }